<template>
  <div>
    <a-card :title="$t('other_title5')" class="my-card" size="default">
      <template>
        <a-button type="primary" style="margin-right: 10px">
          {{ $t('system_settings_btn') }}
        </a-button>
        <a-button type="primary" style="margin-right: 10px">
          {{ $t('system_settings_btn2') }}
        </a-button>
      </template>
      <a-button type="primary" @click="add">
        {{ $t('system_settings_btn3') }}
      </a-button>
      <a-table
        style="margin-top: 20px"
        :columns="columns"
        :data-source="dataSource"
        :pagination="{
          current: form.pageNum,
          pageSize: form.pageSize,
          total: total,
        }"
        @change="handlePageChange"
        :scroll="{ x: 1000 }"
        rowKey="index"
      >
        <template slot="userType" slot-scope="text, record">
          {{
            record.userType === 1
              ? $t('system_settings_text1')
              : record.userType === 2
              ? $t('system_settings_text2')
              : record.userType === 3
              ? $t('system_settings_text3')
              : ''
          }}
        </template>
        <template slot="status" slot-scope="text, record">
          {{
            record.status === 0
              ? $t('system_settings_text4')
              : record.status === 1
              ? $t('system_settings_text5')
              : ''
          }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="update(record)">{{ $t('system_settings_text6') }}</a>
          <a-divider type="vertical" />
          <a @click="stop(record)">
            {{
              record.status === 0
                ? $t('system_settings_text7')
                : $t('system_settings_text8')
            }}
          </a>
          <a-divider type="vertical" />
          <a @click="resetPassword(record)">
            {{ $t('system_settings_text9') }}
          </a>
        </template>
      </a-table>
    </a-card>
    <el-dialog
      :title="`${
        isEdit ? $t('system_settings_text10') : $t('system_settings_text11')
      }`"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="addorUpForm" :rules="rules" ref="addorUpForm">
        <el-form-item
          :label="$t('system_settings_text12')"
          :label-width="formLabelWidth"
          prop="realName"
        >
          <el-input v-model="addorUpForm.realName"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('register_form2')"
          :label-width="formLabelWidth"
          prop="email"
        >
          <el-input v-model="addorUpForm.email"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('register_form3')"
          :label-width="formLabelWidth"
          prop="phone"
        >
          <el-input v-model="addorUpForm.phone"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('system_settings_text13')"
          :label-width="formLabelWidth"
          prop="userType"
        >
          <el-radio v-model="addorUpForm.userType" label="1">
            {{ $t('system_settings_text14') }}
          </el-radio>
          <el-radio v-model="addorUpForm.userType" label="2">
            {{ $t('system_settings_text2') }}
          </el-radio>
          <el-radio v-model="addorUpForm.userType" label="3">
            {{ $t('system_settings_text3') }}
          </el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">
          {{ $t('system_settings_text15') }}
        </el-button>
        <el-button type="primary" @click="handleOk">
          {{ $t('system_settings_text16') }}
        </el-button>
      </div>
    </el-dialog>
    <a-modal
      :title="$t('system_settings_text9')"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOkPassword"
      @cancel="handleCancelPassword"
    >
      <p>{{ $t('system_settings_text17') }}</p>
    </a-modal>
    <a-modal
      :title="`${
        accountList.status === 0
          ? $t('system_settings_text18')
          : $t('system_settings_text19')
      }`"
      :visible="accountvisible"
      :confirm-loading="confirmLoading"
      @ok="handleOkaccount"
      @cancel="handleCancelaccount"
    >
      <p>
        {{ message }}
      </p>
    </a-modal>
  </div>
</template>

<script>
import {
  getOperatorList,
  getAddUser,
  getfrozenUser,
  getresetPassword,
} from './api';
export default {
  data() {
    return {
      form: {
        pageSize: 10,
        pageNum: 1,
      },
      addorUpForm: {
        realName: '',
        email: '',
        phone: '',
        userType: '',
      },
      total: 0,
      dataSource: [],
      dialogFormVisible: false,
      isEdit: true, //true是编辑   false是新增
      formLabelWidth: '120px',
      isEditId: '',
      visible: false, //重置密码弹窗
      accountvisible: false, //停用或启用 用户弹窗
      resetPasswordId: '', //重置密码id
      accountList: {},
      confirmLoading: false,
    };
  },
  created() {
    this._getOperatorList();
  },
  methods: {
    stop(record) {
      this.accountvisible = true;
      this.accountList = record;
      // const params = {
      //   userId: record.userId,
      //   frozen: record.status === 0 ? true : false,
      // };
      // await getfrozenUser(params);
      // this.$message.success('操作成功');
      // this._getOperatorList();
    },
    async handleOkaccount() {
      this.accountvisible = false;
      const params = {
        userId: this.accountList.userId,
        frozen: this.accountList.status === 0 ? true : false,
      };
      await getfrozenUser(params);
      this.$message.success('操作成功');
      this._getOperatorList();
    },
    handleCancelaccount() {
      this.accountvisible = false;
    },
    //重置密码
    async resetPassword(record) {
      this.visible = true;
      this.resetPasswordId = record.userId;
    },
    async handleOkPassword() {
      this.visible = false;
      const params = {
        userId: this.resetPasswordId,
      };
      await getresetPassword(params);
      this.$message.success('操作成功');
      this._getOperatorList();
    },
    handleCancelPassword() {
      this.visible = false;
    },
    add() {
      this.dialogFormVisible = true;
      this.isEdit = false;
    },
    update(record) {
      this.dialogFormVisible = true;
      this.isEdit = true;
      this.isEditId = record.userId;
      this.$nextTick(() => {
        this.addorUpForm.realName = record.realName;
        this.addorUpForm.email = record.email;
        this.addorUpForm.phone = record.phone;
        this.addorUpForm.userType = record.userType.toString();
      });
    },
    handleCancel() {
      this.dialogFormVisible = false;
      this.$refs.addorUpForm.resetFields();
    },
    async handleOk() {
      this.dialogFormVisible = false;
      const valid = await this.$refs.addorUpForm.validate();
      if (valid) {
        if (!this.isEdit) {
          const params = {
            ...this.addorUpForm,
          };
          await getAddUser(params);
        } else {
          const params = {
            ...this.addorUpForm,
            userId: this.isEditId,
          };
          await getAddUser(params);
        }

        this.$message.success('操作成功');
        this.$refs.addorUpForm.resetFields();
        this._getOperatorList();
      }
    },
    handlePageChange(pagination) {
      this.form.pageNum = pagination.current;
    },
    async _getOperatorList() {
      const params = {
        ...this.form,
      };
      const res = await getOperatorList(params);
      this.dataSource = res.dataSet;
      this.total = res.page.totalNumber;
    },
  },
  computed: {
    message() {
      if (this.accountList.status === 0) {
        return this.$t('system_settings_text20');
      } else {
        return this.$t('system_settings_text21');
      }
    },
    columns() {
      return [
        {
          title: this.$t('system_settings_text12'),
          dataIndex: 'realName',
          width: 100,
        },
        {
          title: this.$t('register_form2'),
          dataIndex: 'email',
          width: 200,
        },
        {
          title: this.$t('register_form3'),
          dataIndex: 'phone',
          width: 200,
        },
        {
          title: this.$t('system_settings_text13'),
          dataIndex: 'userType',
          scopedSlots: { customRender: 'userType' },
          width: 200,
        },
        {
          title: this.$t('system_settings_text22'),
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          width: 200,
        },
        {
          title: this.$t('home_page_title8'),
          width: 400,
          scopedSlots: { customRender: 'action' },
        },
      ];
    },
    rules() {
      return {
        realName: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        email: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
        phone: [
          { required: true, message: this.$t('COMMON_ERR1'), trigger: 'blur' },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.my-card {
  margin-left: 20px;
}
</style>
