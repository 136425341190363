import request from '@/utils/request';

export const getOperatorList = params =>
  request.get('/manager-api/manager/search/operator', { params });

//添加或者更新用户
export const getAddUser = params =>
  request.post(`/manager-api/exchange/addUser`, params);

//冻结或者解冻用户
export const getfrozenUser = params =>
  request.post(`/manager-api/exchange/frozenUser`, params);

//重置密码
export const getresetPassword = params =>
  request.post(`/manager-api/exchange/resetPassword`, params);
